var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-start" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mb-3",
              attrs: { color: "primary" },
              on: {
                click: function () {
                  _vm.$router.go(-1)
                },
              },
            },
            [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", [
            _c(
              "div",
              [
                _vm.api.isLoading
                  ? _c("v-skeleton-loader", {
                      ref: "skeleton",
                      attrs: { type: "table" },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      this.sales != null
        ? _c(
            "v-card",
            {
              staticStyle: { "border-radius": "4px 4px 0px 0px" },
              attrs: { color: "secondary" },
            },
            [
              _c("div", { staticClass: "text-h5 pa-5 white--text" }, [
                _vm._v(" Sales "),
              ]),
            ]
          )
        : _vm._e(),
      _vm._l(this.sales, function (item, index) {
        return _c(
          "v-list-item",
          { key: index, staticClass: "px-0" },
          [
            _c(
              "v-container",
              { staticClass: "px-0 py-0" },
              [
                _c(
                  "v-card",
                  { staticStyle: { "border-radius": "0px 0px 0px 0px" } },
                  [
                    _c("div", { staticClass: "pa-4" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-start text-h6 mb-4" },
                        [_vm._v(" " + _vm._s(item.sales_funnel) + " ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-start" },
                        [
                          _vm._v(" Report date : "),
                          _c("ADateFormat", {
                            staticClass: "mx-2",
                            attrs: { date: item.created_at },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "d-flex justify-start" }, [
                        _vm._v(" Salesperson : "),
                        _c("span", { staticClass: "font-weight-bold mx-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(item.email != null ? item.email : "-") +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }